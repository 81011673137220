.awssld--fallAnimation {
  --fall-animation-angle: 16deg;
  --fall-scaling-in-from: 0.9; }
  .awssld--fallAnimation .awssld--moveRight, .awssld--fallAnimation .awssld--moveLeft {
    -webkit-animation: scaleUp calc(var(--slider-transition-duration) / 2) var(--transition-bezier) both;
            animation: scaleUp calc(var(--slider-transition-duration) / 2) var(--transition-bezier) both; }
  .awssld--fallAnimation .awssld--exit {
    z-index: 100; }
    .awssld--fallAnimation .awssld--exit.awssld--moveLeft {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-animation: rotateFallRight var(--slider-transition-duration) both ease-in-out;
              animation: rotateFallRight var(--slider-transition-duration) both ease-in-out; }
    .awssld--fallAnimation .awssld--exit.awssld--moveRight {
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      -webkit-animation: rotateFallLeft var(--slider-transition-duration) both ease-in-out;
              animation: rotateFallLeft var(--slider-transition-duration) both ease-in-out; }

@-webkit-keyframes scaleUp {
  0% {
    -webkit-transform: scale(var(--fall-scaling-in-from));
            transform: scale(var(--fall-scaling-in-from)); }
  75% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes scaleUp {
  0% {
    -webkit-transform: scale(var(--fall-scaling-in-from));
            transform: scale(var(--fall-scaling-in-from)); }
  75% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes rotateFallRight {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  40% {
    -webkit-transform: rotateZ(var(--fall-animation-angle));
            transform: rotateZ(var(--fall-animation-angle)); }
  60% {
    -webkit-transform: rotateZ(calc(var(--fall-animation-angle) - 2deg));
            transform: rotateZ(calc(var(--fall-animation-angle) - 2deg)); }
  100% {
    -webkit-transform: translateY(100%) rotateZ(calc(var(--fall-animation-angle) * 2));
            transform: translateY(100%) rotateZ(calc(var(--fall-animation-angle) * 2)); } }

@keyframes rotateFallRight {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  40% {
    -webkit-transform: rotateZ(var(--fall-animation-angle));
            transform: rotateZ(var(--fall-animation-angle)); }
  60% {
    -webkit-transform: rotateZ(calc(var(--fall-animation-angle) - 2deg));
            transform: rotateZ(calc(var(--fall-animation-angle) - 2deg)); }
  100% {
    -webkit-transform: translateY(100%) rotateZ(calc(var(--fall-animation-angle) * 2));
            transform: translateY(100%) rotateZ(calc(var(--fall-animation-angle) * 2)); } }

@-webkit-keyframes rotateFallLeft {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  40% {
    -webkit-transform: rotateZ(calc(var(--fall-animation-angle) * -1));
            transform: rotateZ(calc(var(--fall-animation-angle) * -1)); }
  60% {
    -webkit-transform: rotateZ(calc((var(--fall-animation-angle) - 2deg) * -1));
            transform: rotateZ(calc((var(--fall-animation-angle) - 2deg) * -1)); }
  100% {
    -webkit-transform: translateY(100%) rotateZ(calc(var(--fall-animation-angle) * -2));
            transform: translateY(100%) rotateZ(calc(var(--fall-animation-angle) * -2)); } }

@keyframes rotateFallLeft {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  40% {
    -webkit-transform: rotateZ(calc(var(--fall-animation-angle) * -1));
            transform: rotateZ(calc(var(--fall-animation-angle) * -1)); }
  60% {
    -webkit-transform: rotateZ(calc((var(--fall-animation-angle) - 2deg) * -1));
            transform: rotateZ(calc((var(--fall-animation-angle) - 2deg) * -1)); }
  100% {
    -webkit-transform: translateY(100%) rotateZ(calc(var(--fall-animation-angle) * -2));
            transform: translateY(100%) rotateZ(calc(var(--fall-animation-angle) * -2)); } }

